











































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CourseResponseCourses } from '../../../../types/typescript-axios/api';
import {
  isWaitingList,
  courseStatusText,
  courseTimeStatus,
  mansekiStatus,
  isReservedStatus,
  isReservedStatus2,
  isReservedStatus3,
  isCourseApplicationMethod,
} from '../../../plugins/helper';
import LabelStatus from '@/components/contents/label/LabelStatus.vue';

@Component({
  components: {
    LabelStatus,
  },
})
export default class CourseReserveButton extends Vue {
  @Prop({default: {}})
  private course!: CourseResponseCourses;

  private get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }

  private get isTimeStatus(): number {
    // 申込み受付期間前：0
    // 申込み受付期間中：1
    // 申込み期間終了：2
    // 開催終了：3
    return courseTimeStatus(this.course);
  }

  private get isWaitngListStatus(): boolean {
    return isWaitingList(this.course); // キャンセル待ちする？
  }

  private get isStatus1(): number {
    // -1: 未申し込み
    // 0: 申込受付
    // 1: キャンセル
    // 2: キャンセル待ち
    return isReservedStatus(this.course);
  }

  private get isStatus2(): number {
    // 講座の予約ステータス2
    // -1: 未申し込み
    // 0: -
    // 1: キャンセル待ち繰り上げ
    // 2: 繰り上げ
    // 3: キャンセル料発生
    // 4: ドタキャン（キャンセル料発生）
    // 5: キャンセル待ち辞退
    return isReservedStatus2(this.course);
  }

  private get isStatus3(): number {
    // 講座の支払い状況
    // -1: 未申し込み
    // 0: -
    // 1: 未入金
    // 2: 入金済
    return isReservedStatus3(this.course);
  }

  private get isManseki(): boolean { // 満席？
    return mansekiStatus(this.course);
  }

  private get isApplicationMethod(): number {
    // 申込方法
    // 先着順 0
    // 抽選 1
    // オンライン 2
    return isCourseApplicationMethod(this.course); // 申込み方法
  }

  private get isStatusText(): string {
    return courseStatusText(this.course, this.isLogin);
  }

  private get isButtonHidden(): boolean {
    return Boolean(Number(this.course.data2.acf?.orderBtnHidden));
  }

  private get isButtonStatus(): number {

    if (this.isTimeStatus === 3) { // 終了
      return 0; // ラベル表示
    }

    if (
        (
          this.isTimeStatus < 3 && // 開催終了より前
          this.isStatus1 === 0 // 申込受付
        ) ||
        (
          this.isTimeStatus < 3 && // 開催終了以外
          this.isStatus1 === 2 && // キャンセル待ち
          this.isStatus2 !== 2 // 繰り上げ確認中以外
        )
    ) {
      return 4; // 申込みキャンセル
    }

    if (
      this.isTimeStatus === 0 // 申込み受付期間前
    ) {
      return 0; // ラベル表示
    }

    if (this.isTimeStatus === 2) { // 申込み期間終了
      return 0; // ラベル表示
    }

    if (
        this.isTimeStatus === 1 && // 受付期間中
        this.isApplicationMethod === 0 && // 先着順
        (this.isStatus1 === -1 || this.isStatus1 === 1) && // 未申込み・キャンセル
        this.isManseki && // 満席
        !this.isWaitngListStatus // キャンセル待ち受付しない
    ) {
      return 0; // ラベル表示
    }

    if (
        this.isTimeStatus === 1 && // 受付期間中
        this.isApplicationMethod === 0 && // 先着順
        (this.isStatus1 === -1 || this.isStatus1 === 1) && // 未申込み・キャンセル
        this.isManseki && // 満席
        this.isWaitngListStatus // キャンセル待ち受付
    ) {
      return 3; // この講座に申込む(キャンセル待ち)
    }

    if (
      (
        this.isTimeStatus === 1 && // 受付期間中
        this.isApplicationMethod === 0 && // 先着順
        this.isStatus1 === -1 && // 未申込み
        !this.isManseki && // 未満席
        !this.isLogin // 未ログイン
      ) ||
      (
        this.isTimeStatus === 1 && // 受付期間中
        this.isApplicationMethod === 0 && // 先着順
        this.isStatus1 === -1 && // 未申込み
        this.isManseki && // 満席
        this.isWaitngListStatus && // キャンセル待ち受付
        !this.isLogin // 未ログイン
      ) ||
      (
        this.isTimeStatus === 1 && // 受付期間中
        (this.isApplicationMethod === 1 || this.isApplicationMethod === 2) && // 抽選・オンライン
        this.isStatus1 === -1 && // 未申込み
        !this.isLogin // 未ログイン
      )
    ) {
      return 1; // この講座に申込む（MOC会員の方）
    }

    if (
      (
        this.isTimeStatus === 1 && // 受付期間中
        this.isApplicationMethod === 0 && // 先着順
        (this.isStatus1 === -1 || this.isStatus1 === 1) && // 未申込み・キャンセル
        !this.isManseki && // 未満席
        this.isLogin // ログイン中
      ) ||
      (
        this.isTimeStatus === 1 && // 受付期間中
        (this.isApplicationMethod === 1 || this.isApplicationMethod === 2) && // 抽選・オンライン
        (this.isStatus1 === -1 || this.isStatus1 === 1) && // 未申込み
        this.isLogin // ログイン中
      )
    ) {
      return 2; // この講座に申込む
    }

    return 0; // ラベル表示
  }

  private get isNoteStatus(): number {
    // console.log(this.isTimeStatus, // 受付期間中
    //     this.isApplicationMethod, // 先着順
    //     this.isStatus1, // キャンセル待ち
    //     this.isStatus2, // 繰り上げ確認中以外
    //     this.isManseki, // 満席
    //     !this.isWaitngListStatus, // キャンセル待ち不可
    //   );

    if (
        this.isButtonStatus === 1 ||
        (this.isButtonStatus === 3 && !this.isLogin)
    ) {
      /**
       * MOC会員登録（無料）はこちらから
       */
      return 3;
    }

    if (
        this.isTimeStatus < 3 && // 開催終了より前
        this.isStatus1 === 2 && // キャンセル待ち
        this.isStatus2 === 2 // 繰り上げ確認中以外
    ) {
      /**
       * ※メールが送信されていますので、本講座の参加確認手続きを行ってください。
       */
      return 4;
    }

    if (
      (
        this.isTimeStatus === 1 && // 受付期間中
        this.isApplicationMethod === 0 && // 先着順
        this.isStatus1 === 2 && // キャンセル待ち
        this.isStatus2 !== 2 && // 繰り上げ確認中以外
        this.isManseki && // 満席
        !this.isWaitngListStatus // キャンセル待ち不可
      ) ||
      (
        this.isTimeStatus === 2 && // 申込み期間終了
        this.isApplicationMethod === 0 && // 先着順
        this.isStatus1 === 2 && // キャンセル待ち
        this.isStatus2 !== 2 // 繰り上げ確認中以外
      ) ||
      (
        this.isTimeStatus === 2 && // 申込み期間終了
        (this.isApplicationMethod === 1 || this.isApplicationMethod === 2) && // 抽選・オンライン
        this.isStatus1 === 2 && // キャンセル待ち
        this.isStatus2 !== 2 // 繰り上げ確認中以外
      )
    ) {
      /**
       * ※上記の「キャンセル」ボタンを押しますと、申込みは取り消しされます。
       */
      return 2;
    }

    if (
      (
        this.isTimeStatus === 1 && // 受付期間中
        this.isApplicationMethod === 0 && // 先着順
        this.isStatus1 === 0 && // 申込受付
        this.isManseki && // 満席
        !this.isWaitngListStatus // キャンセル待ち不可
      ) ||
      (
        this.isTimeStatus === 2 && // 申込み期間終了
        this.isApplicationMethod === 0 && // 先着順
        this.isStatus1 === 0 // 申込受付
      ) ||
      (
        this.isTimeStatus === 2 && // 申込み期間終了
        (this.isApplicationMethod === 1 || this.isApplicationMethod === 2) && // 抽選・オンライン
        this.isStatus1 === 0 // 申込受付
      )
    ) {
        // ※上記の「キャンセル」ボタンを押しますと、申込みは取り消しされます。
        // ※キャンセル手続きのタイミングによりキャンセル料が発生いたします。詳細はこちらをご覧ください。
      return 5;
    }

    if (
      (
        this.isTimeStatus === 1 && // 申込み受付期間中
        this.isApplicationMethod === 0 && // 先着順
        this.isStatus1 === 0 && // 申込受付
        !this.isManseki
      ) ||
      (
        this.isTimeStatus === 1 && // 申込み受付期間中
        this.isApplicationMethod === 0 && // 先着順
        this.isStatus1 === 0 && // 申込受付
        this.isWaitngListStatus // キャンセル待ち可
      ) ||
      (
        this.isTimeStatus === 1 && // 申込み受付期間中
        (this.isApplicationMethod === 1 || this.isApplicationMethod === 2) && // 抽選・オンライン
        this.isStatus1 === 0 // 申込受付
      )
    ) {
      /**
       * ※上記の「キャンセル」ボタンを押しますと、申込みは取り消しされます。再度の申込みは、本画面からの手続きが必要となります。
       * ※キャンセル手続きのタイミングによりキャンセル料が発生いたします。詳細はこちらをご覧ください。
       */
      return 0;
    }

    if (
      (
        this.isTimeStatus === 1 && // 申込み受付期間中
        this.isApplicationMethod === 0 && // 先着順
        this.isStatus1 === 2 && // キャンセル待ち
        this.isStatus2 !== 2 && // 繰り上げ確認中以外
        !this.isManseki
      ) ||
      (
        this.isTimeStatus === 1 && // 申込み受付期間中
        this.isApplicationMethod === 0 && // 先着順
        this.isStatus1 === 2 && // キャンセル待ち
        this.isStatus2 !== 2 && // 繰り上げ確認中以外
        this.isWaitngListStatus // キャンセル待ち可
      ) ||
      (
        this.isTimeStatus === 1 && // 申込み受付期間中
        (this.isApplicationMethod === 1 || this.isApplicationMethod === 2) && // 抽選・オンライン
        this.isStatus1 === 2 && // キャンセル待ち
        this.isStatus2 !== 2 // 繰り上げ確認中以外
      )
    ) {
      /**
       * ※上記の「キャンセル」ボタンを押しますと、申込みは取り消しされます。再度の申込みは、本画面からの手続きが必要となります。
       */
      return 1;
    }

    return -1; // 表示なし
  }
}
