



import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { CourseResponseCourses } from '../../../../types/typescript-axios/api';
import VueRouter, {Route} from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import {
  courseStatusText,
  mansekiStatus,
  courseTimeStatus,
  isCourseApplicationMethod,
  isWaitingList,
  sortCourseList,
} from '../../../plugins/helper';
import CourseList from '../../../components/contents/course/CourseList.vue';
import CourseReserveButton from '../../../components/contents/course/CourseReserveButton.vue';
import LabelStatus from '@/components/contents/label/LabelStatus.vue';
import LabelApplicationMethodStatus from '@/components/contents/label/LabelApplicationMethodStatus.vue';
import Breadcrumb from '@/components/contents/breadcrumb/Breadcrumb.vue';
import store from '@/store';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

@Component({
  components: {
    Breadcrumb,
    CourseList,
    CourseReserveButton,
    LabelStatus,
    LabelApplicationMethodStatus,
  },
})
export default class CourseDetail extends Vue {
  // private paramsName: any = '';
  private teacher: any = [];
  // private etcData: WPPost[] = [];
  private total = 0;
  get breadcrumb(): BreadcrumbArr {
    return [
      {
        title: 'HOME',
        link: '/',
      },
      {
        title: '講座情報',
        link: process.env.VUE_APP_PATH_COURSE,
      },
      {
        title: this.courseData.data2.title.rendered,
        link: '',
      },
    ];
  }
  // private courseData: any = [];
  private get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }
  private get courseData(): any {
    const $parent: any = this.$parent;
    return $parent.courseDetail;
  }
  private get etcData(): any {
    const $parent: any = this.$parent;
    return sortCourseList($parent.etcData);
  }

  private get mainImage(): string {
    let imagePath: any = null;
    if (
      typeof this.courseData.data2.acf.main_image !== 'undefined' &&
      this.courseData.data2.acf.main_image
    ) {
      imagePath = {};
      imagePath['320w'] = this.courseData.data2.acf.main_image.sizes['320w'];
      imagePath['320'] = this.courseData.data2.acf.main_image.sizes['320'];
      imagePath.caption = this.courseData.data2.acf.main_image.caption;
      return imagePath;
    }
    if (
      !imagePath &&
      typeof this.courseData.data2.thumbnails['320'] !== 'undefined'
    ) {
      imagePath = {};
      imagePath['320w'] = this.courseData.data2.thumbnails['320w'].path;
      imagePath['320'] = this.courseData.data2.thumbnails['320'].path;
      imagePath.caption = this.courseData.data2.thumbnails.thumbnail.caption;
    }
    return imagePath;
  }

  private get courseTitle() {
    const $acf: any = this.courseData.data2.acf;
    if ($acf.detail_title !== '') {
      return this.brReplace($acf.detail_title);
    } else {
      return this.courseData.data2.title.rendered;
    }
  }

  private get isStatusText(): string {
    return courseStatusText(this.courseData, this.isLogin);
  }

  private get paramsName(): string {
    return this.$route.params.courceId;
  }

  private get isMansekiStatus(): boolean {
    return mansekiStatus(this.courseData);
  }

  private get isTimeStatus(): number {
    return courseTimeStatus(this.courseData);
  }

  private get isApplicationMethod(): number {
    return isCourseApplicationMethod(this.courseData); // 申込み方法
  }

  private get isWaitngListStatus(): boolean {
    return isWaitingList(this.courseData); // キャンセル待ちする？
  }

  private get title(): any {
    const $parent: any = this.$parent;
    return $parent.title;
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    store.commit('window/setTitle', store.getters['window/isTitle'] + ' 講座 ' + process.env.VUE_APP_TITLE_SUFFIX);
    next();
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: any) {
    store.commit('window/setTitle', store.getters['window/isTitle'] + ' 講座 ' + process.env.VUE_APP_TITLE_SUFFIX);
    this.teacher = [];
    if (this.courseData.data2.teachers.length > 0) {
      for (const data of this.courseData.data2.teachers) {
        const id: number = data.term_id;
        const url: string = `/wp-json/moc/course/teacher/${id}`;
        axios.get<AxiosResponse>(url)
        .then((response: any) => {
          this.teacher.push(response.data);
        });
      }
    }
    next();
  }

  private mounted() {
    if (this.courseData.data2.teachers.length > 0) {
      for (const data of this.courseData.data2.teachers) {
        const id: number = data.term_id;
        const url: string = `/wp-json/moc/course/teacher/${id}`;
        axios.get<AxiosResponse>(url)
        .then((response: any) => {
          this.teacher.push(response.data);
        });
      }
    }
  }

  @Emit()
  private dateFilter(date: string) {
    const d: Date = new Date(date);
    const dayArr = ['日', '月', '火', '水', '木', '金', '土'];
    const day = dayArr[d.getDay()];
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日（${day}）`;
  }

  @Emit()
  private brReplace(item: string) {
    let result = '';
    result = item.replace( /\n|\r\n|\r/g, '<br>' );
    return result;
  }

}
